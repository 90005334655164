/* section 1 */
.section-1
{
    width: 100%; 
    background: url(/assets/images/la_grange_a_louis_header.png) no-repeat bottom center; 
    background-size: cover;
    color: $primary-white;
   
}
    
    .container-s1
    {
         padding-top: 139px;
         text-align: center;
    }

    .container-slogan
    {
        text-align: center;

    }

    .s1_label
    {
        font-family: "Myriad Pro Bold";
        text-transform: uppercase;
        padding: 50px;
        letter-spacing: 4px;
    }
            .btn-boulanger, .btn-boulanger:visited ,.btn-boulanger:active, .btn-boulanger:target , .btn-boulanger:focus 
            {
                border-radius: 5px;
                padding: 5px 10px ;        
                color: $primary-white;
                background-color: var(--primary-transparent);
                border: 1px solid $primary-white;
            }

            .btn-boulanger:hover{
                color: $primary-grey-dark;
                background-color: $primary-white;
                border-color: $primary-white;
            }
    .s1_slogan > div
    {
        display: inline-block; 

    }

        .slogan_right
        {
            margin-left : 60px;
            position: relative;
        }

        .slogan_left
        {
            margin-left : 10px;
            position: relative;
        }


    .s1_section
    {
       padding: 60px 0px;
     
        width: 100%;
        text-align: center;
    }

    .s1_section_container
    {
        display: inline-block;
    }
    .s1_section_container > h2
    {
        float: left;
        display: block;
        width: 150px;
        padding: 11px 21px;
        font-size: 14px;
    }

    .s1_section_R ,.s1_section_R:visited ,.s1_section_R:active, .s1_section_R:target , .s1_section_R:focus 
    {
        color: $primary-grey-dark;
        background-color: $primary-white-80;
    
        -webkit-border-top-right-radius: 55px;
        -webkit-border-bottom-right-radius: 55px;
        -moz-border-radius-top-right: 55px;
        -moz-border-radius-bottom-right: 55px;
        border-top-right-radius: 55px;
        border-bottom-right-radius: 55px;
      
        
    }
    

    .s1_section_L, .s1_section_L:visited ,.s1_section_L:active, .s1_section_R:target , .s1_section_L:focus 
    {

        background-color: $primary-grey-dark-80;
        color: $primary-white;
    
        -webkit-border-top-left-radius: 55px;
        -webkit-border-bottom-left-radius: 55px;
        -moz-border-radius-top-left: 55px;
        -moz-border-radius-bottom-left: 55px;
        border-top-left-radius: 55px;
        border-bottom-left-radius: 55px;        
    }
   

.text-4
{
     font-family: 'Amatic bold';
   font-size: 5em;
   

}
.text-4 > p{
    display: inline-block;
    position: relative;
}
.logo-txt-4 >span
{

    font-size: 5em;
}

.text-4-l
{
    position: absolute !important;
    left: 50%;
    transform: translateX(-115%);
    top: 20px;
}

.logo-epis
{
    text-align: center;
    width: 30px;
    z-index: 99999;
}
.container-epis
{
    text-align: center;
    position: absolute; 
    left :50%;
    transform : translateX(-50%);
}
.graine-right
{
    position: absolute;
    bottom:0;
    transform: translateX(+150%);
    height: 20px;
    right:0;
}
.graine-left
{
    position: absolute;
    bottom: 0;
    transform: translateX(-150%);
    left:0;
    height: 20px; 
}