.cart-epis{
	display: inline-block;

	position: absolute;
	top: 30px;
	 -webkit-transform: translate(-130%, -110%) rotate(-40deg);
              transform: translate(-130%, -110%) rotate(-40deg);
	width: 40px;
	heigth: auto;
}

.cart-container-tradition{
	background-image: url(/assets/images/items/patterns-pain.svg);
    background-repeat: repeat;
    background-size: 600px,80px,200%;

  background-color: $primary-grey-dark ;
}