.block-image  > img
{
	float : left;
	padding:10px;
}


.content-txt
{
	padding: 0px 20px 0px 20px;
}