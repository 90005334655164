.g-map-canvas {
  width: 100%;
  height: 400px;
}
.ember-google-map {
  width: 100%;
  height: 400px;
}
.contact-ico{
	font-size: 3em;
	color: $secondary-yellow;
}
.info-contact
{

     padding-top: 10px;
	color: white;
	    font-size: 1.2em;
}
.label-content{

     padding-top: 20px;
	color : $secondary-yellow;
	font-size: 1.8em;
}
.contact-container
{
	background-color: $primary-grey-dark;
	background-image: url(/assets/images/items/patterns-pain.svg);
    background-repeat: repeat;
    background-size: 600px,80px,200%;
 
}

.contact-content
{
	color:white;
	padding: 60px;
}

