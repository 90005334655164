/* header */ 
header
{
    z-index: 9999;
    position: fixed;
    width: 100%;
}

.commander-ico{
    height: 12px;
    margin: 0px 5px 3px 0px;
}
.head_action > *{
    float: right;
   /* display: inline-block;*/
}
/* Nav Button */
.nav-button{
    display:none;
}

.nav-button-bar1, .nav-button-bar2, .nav-button-bar3 {
    width: 35px;
    height: 5px;
    background-color: $primary-white;
    margin: 6px 0;
    transition: 0.4s;
}
.open > .nav-button-bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.open >.nav-button-bar2 {opacity: 0;}

.open > .nav-button-bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}
/* top header */
    .head_top
    {
        background-color: $primary-grey-dark-95;
    }

    .head_content 
    {
        position: relative;
    }

        .head_social_media
        {
           position: absolute;
        }

        .head_content > .head_social_media > ul 
        {
            padding: 25px;
        }
        /* header_mini navbare */
        .header_mini  .head_social_media > ul 
        {
            padding: 9px;
        }

            .icon_sm,.icon_sm:visited ,.icon_sm:active, .icon_sm:focus { /* global button class */
                display: inline-block;
                position: relative;
                cursor: pointer;
                width: 30px;
                height: 30px;
                border:1px solid $secondary-yellow; /* add border to the buttons */
                font-family: "FontAwesome";
                padding: 0px;
                text-decoration: none;
                text-align: center;
                color: $primary-grey-light;
                font-size: 16px;
                font-weight: normal;
                line-height: 1.8em;
                border-radius: 27px;
                -moz-border-radius:27px;
                -webkit-border-radius:27px;

                letter-spacing: 0em;
                color: $primary-grey-light;
                text-decoration: none;
            }

            .facebook_sm:before {              
                content: "\f09a"; 
            }

            .facebook_sm:hover{
                color: $primary-grey-dark;
                background-color: $secondary-yellow;
                border-color: $secondary-yellow; 
            }

            .twitter_sm:before{
                content: "\f099"; 
            }

            .twitter_sm:hover{
              color: $primary-grey-dark;
                background-color: $secondary-yellow;
                border-color: $secondary-yellow; 
            }

            .instagram_sm:before{
                content: "\f16d"; 
            }

            .instagram_sm:hover{
                color: $primary-grey-dark;
                background-color: $secondary-yellow;
                border-color: $secondary-yellow; 
            }

        .head_logo
        {
            text-align: center;
            color:$primary-white;
        }
        .head_content > .head_logo
        {
            padding: 10px;
        }

        /* header_mini navbare */
        .header_mini  .head_logo
        {
            padding: 4px;
        }

            .head_content > .head_logo > img
             {
                height: 60px;               
             }
             /* header_mini navbare */
             .header_mini  .head_logo > img
             {
                height: 40px;
             }
             
        .head_action
        {
            position: absolute;
            top:0;
            right:0;
        }

        .head_content > .head_action
        {
            padding: 24px;            
        }

         /* header_mini navbare */
        .header_mini  .head_action
        {
            padding: 8px;
        }

            .btn-commander{
                margin: 4px;
                 display: block;
            }
            
           .btn-commander-mobile{
                display: none;
                margin: auto;
                 border-radius: 4px;
                padding: 5px 20px 5px 20px;        
                color: $primary-grey-dark;
                background-color: white;
                border: 1px solid $text-orange;
                text-transform: uppercase;
            }

            .btn-commander, .btn-commander:visited ,.btn-commander:active, .btn-commander:target , .btn-commander:focus 
            {
                border-radius: 4px;
                padding: 5px 20px 5px 20px;        
                color: $primary-grey-dark;
                background-color: white;
                
                text-transform: uppercase;
               
            }

            .btn-commander:hover{
                color: $primary-grey-dark;
                background-color: $text-orange ;
                
              
            }

/* header bottom */
    .head_bottom
    {
        background-color: $primary-white;
        font-size: 1.4em;

    }

     .header_mini  .head_bottom
     {
        font-size: 1em;
     }

    .head_navbar
    {
        text-align: center;
    }

    .head_navbar > ul  > li
    {
        padding: 15px;
    }
    
    .navbar_link
    {
        color: $primary-grey-dark;
        text-transform: uppercase;
    }
    .navbar_link:hover,.navbar_link:focus {
        color: $text-orange;
    }
    .navbar .navbar-nav {
         display: inline-block;
        float: none;
        vertical-align: top;
    }

    .navbar .navbar-collapse {
        text-align: center;
    }



/*tablette*/
@media screen and (max-width: 991px){
   /*Menu*/
   
    

   /*
   .head_navbar > ul > li{
    width:100%;
   }
   .head_bottom
   {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 85%;
    height: 100%;
   }

   .head_navbar{
    display: table-cell;
    text-align: left;
    height: 100%;
   }*/

}


/* smartphone */
@media (max-width: 767px) {
    .logo >img
    {
        height: 40px;
    }
    .nav-button{
       display: block;
       margin-left: 15px;
    }

    .header_mini  .head_bottom
     {
        font-size: 1.6em;
     }

    .btn-commander{
       display: none;
    }

    .btn-commander-mobile{
        display: block;
    }

    .navbar-default .navbar-toggle 
    {
        margin: 10px;
    }

    .nav-pane {
      padding-left: auto;

      transition: 250ms;
      position: fixed;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      top: 50;
      height: 100vh;
      width: 300px;
      background: $primary-white-95;
    display: flex;
        padding-left: auto;
      
      justify-content: space-around;
      box-shadow: 0px 3px 10px rgba(33, 33, 33, 0.5);
    }
    .head_bottom {
        font-size: 1.6em;
    }

    .nav-pane > ul
    {
        margin-top: 50px;
        padding: 0;
    }

    .nav-pane.open {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      transition: 250ms;
    }
    

}

@media (max-width: 490px) {
    

}
