@font-face {
    font-family: 'Myriad Pro Bold';
    src: url('/assets/fonts/MyriadPro-BoldCond.otf');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'Amatic bold';
    src: url('/assets/fonts/AmaticSC-Bold.ttf');
}

@font-face {
    font-family: 'Asar Regular';
    src: url('/assets/fonts/Asar-Regular.ttf');
}

@font-face {
    font-family: 'DansingScript Regular';
    src: url('/assets/fonts/DancingScript-Regular.ttf');
}

@font-face {
    font-family: 'Caption Italic';
    src: url('/assets/fonts/PT_Serif-Caption-Web-Italic.ttf');
}
@font-face {
    font-family: 'Caption';
    src: url('/assets/fonts/PT_Serif-Caption-Web-Regular_0.ttf');
}

@font-face {
    font-family: 'Vladimir';
    src: url('/assets/fonts/VLADIMIR.TTF');
}