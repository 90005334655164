#footer {
	margin-top: 5px;
	background-color: $primary-grey-dark-95;
	height: 60px;
	float: left;
	width: 100%;
	text-align: center;
}

.texte-cc{
	padding: 20px;
	color: white;
	
}
