$primary-grey-dark : rgba(43,43,43,1);
$primary-grey-dark-95: rgba(43,43,43, 0.95);
$primary-grey-dark-80:rgba(43,43,43, 0.80);
$primary-grey-dark-60:rgba(43,43,43, 0.60);

/*$primary-grey-dark : unquote('#222325');
$primary-grey-dark-95: rgba(34, 35, 37, 0.95);
$primary-grey-dark-80:rgba(34, 35, 37, 0.80);
$primary-grey-dark-60: rgba(34, 35, 37, 0.60);
*/
$primary-grey-light: unquote('#a9b3b4');
$secondary-yellow : rgba(254,195,91,1);
/*$secondary-yellow : rgba(229,205,115,0.8);*/

$primary-white: unquote('#FFFFFF');
$primary-white-95: rgba(255,255,255,0.95);
$primary-white-80: rgba(255,255,255,0.80);
$primary-white-60: rgba(255,255,255,0.60);

$primary-transparent: rgba(0,0,0,0);

$content-grey : unquote('#666d70');
$background-cart-grey : unquote('#1c1919');
$br-grey : unquote('#dddddd');
/*texte*/

$text-grey : #3b3d40;
$text-orange : rgba(224,168,68,1);/*rgba(202,164,119,1);*/
/*$text-orange : #c3894a;*/

