#galerie > aside
{
	padding: 0px;
}
.img-galerie-l{
	
	background-image: url('/assets/images/content/galerie-des-pains-1.png');

}

.img-galerie-m{

	background-image: url('/assets/images/content/galerie-des-pains-2.png');
	
	
}

.img-galerie-r{


	background-image: url('/assets/images/content/galerie-des-pains-3.png');


}
.img-galerie {
	overflow: hidden;
background-position: center;
	background-size: cover;
	height: 400px !important;

} 
/*
#galerie > .cart-container
{
	height: auto !important;
}*/

