
/*
@media screen and (max-width: 1366px) {
  
  
}

@media (min-width: 768px) and (max-width: 1199px) {
  
}


/*tablette*/
@media screen and (max-width: 991px){
   /*Menu*/
   


   /*
   .head_navbar > ul > li{
   	width:100%;
   }
   .head_bottom
   {
   	position: absolute;
	top: 0;
	bottom: 0;
	width: 85%;
	height: 100%;
   }

   .head_navbar{
   	display: table-cell;
   	text-align: left;
   	height: 100%;
   }*/

}


/* smartphone */
@media (max-width: 767px) {
    .logo >img
    {
        height: 40px;
    }
    .navbar-default .navbar-toggle 
    {
        margin: 10px;
    }

}

@media (max-width: 490px) {
    

}


/*iphone4/5

@media only screen (max-width: 568px)  {

 header 


   
}*/