/*Global CSS*/ 
/* COLOR */
@import 'bootstrap';
@import "ember-power-select";


/*modale */
@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";



@import 'defaults/color';
@import 'defaults/fonts';

/*@import 'admin/menu';

@import 'boutique/util';
@import 'boutique/produit';*/

@import 'landing-page/menu';
@import 'landing-page/section-1';
@import 'landing-page/section-empreinte';
@import 'landing-page/section-air-de-tradition';
@import 'landing-page/section-nos-engagements';
@import 'landing-page/section-galerie';
@import 'landing-page/map';
@import 'landing-page/footer';

/*animation*/
@import 'landing-page/animate';

/*Responsive*/
@import 'landing-page/responsive';


.card-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width:100%;
  height:250px;
  overflow:hidden;
  position: relative;
}

.caption {
  min-height: 230px;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #5bc0de;
}

.card-img:hover .overlay {
  opacity: 1;
}

.text-card {
  padding:10px;
  width: 100%;
  color: white;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}



.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: none !important;
  animation-fill-mode: none !important;
}

html, body
{
  font-family: "Myriad Pro Bold";    
  letter-spacing: 0.15em;  
  margin: 0;
}

section{
	float: left;
    width: 100%;
}
.none
{
  display: none;
}

.center
{
  text-align: center;
}

.cart-text
{
  font-size: 1.5em !important;
  margin: 15px !important;
}

.text-inv{
  text-align: center;
  color: $text-orange;
  font-family:'Vladimir';
  margin-bottom: 0px; 
  font-size: 4.2em;
}
.sub_text-inv
{
  text-align: center;
   color: $text-grey;
  font-family:'Caption';
  margin-top: 0px; 
  font-size: 2.5em;
  margin-bottom: 30px;
}

.text{
  text-align: center;
  color: $text-grey;
  font-family:'Caption';
  /*font-weight: bold;*/
  font-size: 3em;
}
.sub_text
{
  text-align: center;
  color: $text-orange;
  font-family:'Caption Italic';
  font-size: 0.9em;
  margin-bottom: 30px;
}
.contenu{
  text-align: justify;
  font-size: 1.3em;
  text-align: justify;
   font-family:'Caption';
   color: $content-grey;
}
.wrapper{
  padding: 100px 100px 100px 100px;
}

.img-content{
  padding-top: 50px; 
  margin: auto;
  max-width: 450px;
  text-align: center;
  width: 100%;

  height: auto; 
  display: block;
  float: right;
    margin-left: auto;
    margin-right: auto;

}
.img-content-l{
    padding-top: 50px; 
  margin: auto;
  max-width: 450px;
  text-align: center;
  width: 100%;

  height: auto; 
  display: block;
  float: left;
    margin-left: auto;
    margin-right: auto;
  }

/*.wl{
  padding-right: 30px !important;
}
.wr{
  padding-left: 30px !important;
} */

hr {
    border-top: 2px solid $br-grey;
}
.hr-mini{
  margin-top:-5px;
  margin-bottom:5px;
  width: 70%;
}

.hr-contenu{
  margin-bottom: 50px;
}
.cart-container{
  height: 400px;
  width: 100%;
  position: relative;
}
.cart-content
{
   /*height: 170px;*/
   z-index: 10;
  width: 400px;
  position: absolute;
  margin-left: 0;
  margin-right: 0;
 
  background-color:white;

top:50%;
left: 50%;
    -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%, -50%);
}
/* Flash message */
.flash-message {
  position: fixed;
    z-index: 9999;
    width: 50%;
    right: 5px;
    top: 5px;
}
/*Loader*/
.fullscreen{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #333333;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0.5;
}
.spinner {
  margin: auto 0;
  width: 70px;
  text-align: center;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.spinner > div {
  width: 18px;
  height: 18px;
background-color: white;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
/* GLOBALE */
li
{
   list-style-type: none
}

ul
{
    margin-bottom: 0px;
}
a:hover, a:visited ,a:active, a:target , a:focus 
{
    text-decoration-skip: spaces;
    text-decoration-style: dotted;
    text-decoration: none;
}

.annim-0-3
{
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

/*Modale*/
.ember-modal-dialog {
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
    width: 600px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    margin: 0;
    position: absolute;
    background: #fefefe;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom:30px;
    box-shadow: 5px 10px 40px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.bouttons {
    padding: 10px 30px;
    font-size: 20px;
    background-color: #36a3f7;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    letter-spacing: 0.1em;
    color: #ffffff;
    margin-right: 20px;
    margin-bottom: 15px;
    transition: all 0.25s ease-in-out;
}
.modale-overlay{

  z-index: 999999 !important;
  position: fixed;
  background-color :#00000080;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 1050;
transition: opacity 0.15s linear;
outline: 0;
}

.top-header{
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 200px;
  background-color:#36a3f7;
  background-image: url(/assets/images/items/patterns-pain.svg);
    background-repeat: repeat;
    background-size: 500px,80px,200%; 
}
/*end modale*/

.content-modal-p{
  font-size: 17px;
  padding: 5px 0px 30px 0px;
}

.w50
{
  width: 50%;
}

.w100
{
  width: 100%;
}

.fl
{
  float: left;
}.fr
{
  float: right;
}

.p0{
  padding: 0px;
}
.p20 {
  padding: 20px;

}
.m0
{
  margin: 0px auto;
}
.m50{
  margin: 50px;
}
.tc{
  text-align: center;
}
.nav-pane > ul > li {
        display: inline-block !important;
        padding: 15px;
        font-size: 1em;
    }

/*tablette*/
@media screen and (max-width: 991px){

	.aside-img-content{
		float: none ;
	}
	.img-content{
	float: none ;
	margin-left: auto;
	margin-right: auto;
	}

	.img-content{
	float: none ;
	margin-left: auto;
	margin-right: auto;
	}
  

  .wrapper{
    padding: 50px 50px 50px 50px;
  }

}

/* smartphone */
@media (max-width: 767px) {

.nav-pane > ul > li {
        display: block !important;
        font-size: 1em;
       
    }
  .ember-modal-dialog{
    width: 85%;
  }
  .content-modal-p{
    font-size: 15px;
  }
  .ico-pain-modal{
    height: 60px;
  }
  .top-header{
    height: 160px;
  }
    body{
      letter-spacing: 0.05em;
    }
    .text{
      font-size: 2.5em;
    }
    .sub_text {
      font-size: 1.1em;
    }
    .wrapper {
        padding: 35px 15px 35px 15px;
    }
    .contenu{
      font-size: 1.1em;
    }
    .text-inv{
          font-size: 3em;
    }
    .sub_text-inv{
      font-size: 1.8em;
    }
    .cart-content{
      width:250px;
    }
    .cart-container{
      height: 250px;
    }
    .cart-epis{
      width: 20px;
      -webkit-transform: translate(-130%, -160%) rotate(-40deg);
    transform: translate(-130%, -160%) rotate(-40deg);
    }
    .logo >img
    {
        height: 40px;
    }
    .text-4{
        font-size: 3em;
    }
    /* Menu */
    .logo-epis{
      width: 20px;
    }
    .slogan_right{
      margin-left: 15px;
    }
     .slogan_left{
      margin-right: 15px;
    }
    .graine-left{
      height: 15px;
          transform: translateX(-50%);
    }
    .graine-right{
      height: 15px;
          transform: translateX(50%);
    }
    .s1_label{
      font-size: 13px;
      letter-spacing: 2px;
      padding: 70px;
    }
    .container-s1 {
      padding-top: 80px;
      min-height: 500px;
    }
    .s1_section_container > a {
      width: 110px;
      padding: 8px 0px;
    }

    /*engagement*/
    .img-ico {
      width: 70px;
    }
    /*galerie*/
    .img-galerie {
      height: 250px !important;
    }
    /*contact */
    .contact-content
    {
      color:white;
      padding: 20px;
    }
    .contact-ico{
      font-size: 2.5em;
      color: $secondary-yellow;
    }
    .info-contact
    {
      padding-top: 5px;
      color: white;
       font-size: 1em;
    }
    .label-content{

         padding-top: 10px;
      color : $secondary-yellow;
      font-size: 1.5em;
    }
    /*menu*/
    .head_content > .head_logo > img {
        height: 45px;
    }
    .head_content > .head_action {
        padding: 13px;
    }
    .head_content > .head_social_media > ul {
        padding: 18px;
    }
    
    .nav-pane > ul >li {
        display: inline-block;
        padding-left: 2px;
        padding-right: 1px;
        width: auto;
    }
    .icon_sm, .icon_sm:visited, .icon_sm:active, .icon_sm:focus {
      width: 25px;
      height: 25px;
      font-size: 13px;
      line-height: 1.8em;
    }
    .header-commande{
      display: block;
      padding: 5px 10px 5px 10px;
  
    }
    .header-commande > img{
  
      margin: 0px auto;
    }
    .icon_sm{
      display: none;
    }
    .head_content > .head_social_media > ul {
      padding: 18px 2px 18px 2px;
    }
    .header_mini .head_content > .head_social_media > ul {
      padding: 10px 2px 10px 2px;
    }
    .header_mini .head_content > .head_action {
    padding: 8px;
}

}

@media (max-width: 490px) {
    

}

@media (max-height:  426px) and  (max-width: 767px) {

   .nav-pane > ul > li {
       padding: 5px;
       font-size: 0.7em;
       
    }
    .nav-pane > ul {
      margin-top: 10px;
      padding: 0;
    }
  
}

@media (max-height:  426px) and  (max-width: 767px) {

   .nav-pane > ul > li {
       padding: 5px;
       font-size: 0.7em;
       
    }
    .nav-pane > ul {
      margin-top: 10px;
      padding: 0;
    }
  
}

@import "ember-power-select";
